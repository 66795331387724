import styled, { css } from 'styled-components';

import media from './media';

export const container = css`
  overflow-x: auto;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;

  ${media.tablet`
    padding: 0 24px;
  `}

  ${media.medium`
    box-sizing: content-box;
  `}
`;

const Container = styled.div`
  ${container};
  white-space: pre-wrap;
`;

export default Container;
