import styled from 'styled-components';
import { media, colors } from '../../ui/common';

import Container from '../../ui/Container';

export const Section = styled.section`
  padding-top: 30px;
  padding-bottom: 120px;
  margin-top: 80px;

  ${media.medium`
    padding-top: 60px;
    padding-bottom: 120px;
  `}
  a {
    color: ${colors.color13};
    text-decoration: none;
  }
`;

export const Wrapper = styled(Container)`
  max-width: 960px;
  margin: 0 auto;
`;

export const Title = styled.h4`
  font-weight: 500;
  font-size: 28px;
  color: ${colors.color05};
  margin: 16px 0;
`;
export const Subtitle = styled.h3`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 28px;
  margin-top: 65px;
  margin-bottom: 0;
  margin-left: -1.5em;
`;
export const Headline = styled.h1`
  text-align: center;
  margin-top: 0;
  margin-bottom: 24px;
  color: ${colors.color05};
  font-size: 38px;
  line-height: 1.3;
  ${media.tablet`
    font-size: 46px;
  `}
  ${media.medium`
    font-size: 50px;
    margin-bottom: 54px;
  `}
`;

export const Text = styled.div`
  margin: 30px auto;
  font-size: 14px;
  color: ${colors.color01};
  font-weight: 300;
  line-height: 1.75;
  ${media.medium`
    margin-bottom: 18px;
    box-sizing: border-box;
    line-height: 1.5;
  `}
`;

export const List = styled.ol`
  margin: 30px auto;
  padding-left: 1rem;
  font-size: 14px;
  line-height: 1.75;
  color: ${colors.color01};
  font-weight: 300;
  li {
    padding: 8px 0;
  }
`;

export const Ol = styled.ol`
  padding-left: 2rem;
  margin: 30px auto;
  font-weight: 500;
  font-size: 28px;
  color: ${colors.color05};
  ol {
    padding-left: 0;
    font-size: 14px;
    color: ${colors.color01};
    font-weight: 300;
  }
`;

export const Li = styled.li`
  padding: 8px 0;
  line-height: 1.5;
`;
