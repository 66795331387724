import { css } from 'styled-components';

export const sizes = {
  fullHD: 1920,
  desktop: 1400,
  laptop: 1200, // XL
  medium: 1024, // LG
  tablet: 760, // MD
  phone: 600,
  small: 400,
};

export default Object.keys(sizes).reduce((accumulator, label) => {
  // const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => `
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});
