import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Section, Wrapper, Headline, Text, List } from './styled';

export default function TermsPrivacy() {
  return (
    <Section>
      <Wrapper>
        <Headline>
          <FormattedMessage id="privacy.title" />
        </Headline>
        <Text>
          <FormattedMessage id="privacy.text" />
          &nbsp;
          <a href="https://support.evapolar.com/hc/en-us/requests/new">
            https://support.evapolar.com/hc/en-us/requests/new
          </a>
          .
        </Text>

        <List>
          <li>
            <FormattedMessage id="privacy.item1" />
          </li>
          <li>
            <FormattedMessage id="privacy.item2" />
          </li>
          <li>
            <FormattedMessage id="privacy.item3" />
          </li>
          <li>
            <FormattedMessage id="privacy.item4" />
          </li>
          <li>
            <FormattedMessage id="privacy.item5" />
          </li>
          <li>
            <FormattedMessage id="privacy.item6" />
          </li>
          <li>
            <FormattedMessage id="privacy.item7" />
          </li>
          <li>
            <FormattedMessage
              id="privacy.item8"
              values={{
                // eslint-disable-next-line react/no-unstable-nested-components
                privacyPolicy: (chunks) => (
                  <a href="https://rakutenadvertising.com/legal-notices/services-privacy-policy/">
                    {chunks}
                  </a>
                ),
                // eslint-disable-next-line react/no-unstable-nested-components
                subjectRequests: (chunks) => (
                  <a href="https://rakutenadvertising.com/legal-notices/subject-requests/">
                    {chunks}
                  </a>
                ),
              }}
            />
          </li>
        </List>
      </Wrapper>
    </Section>
  );
}
