import React, { useEffect } from 'react';
import Privacy from '../components/Terms/Privacy';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';
import { mixpanelTrack } from '../utils/datalayer/dataLayer';

function IndexPage() {
  useEffect(() => mixpanelTrack('privacy'));
  return (
    <>
      <NavBar />
      <Privacy />
      <Footer />
    </>
  );
}

export default IndexPage;
